import gamecashLogo from '~/public/store/gamecash.png';
import smartoysLogo from '~/public/store/smartoys.png';

export const useWebStore = defineStore("webStore", () => {

    const storeInCookie = useCookie('store');

    const state = {
        smartoys: {
            img: {
                logo: smartoysLogo,
                height: 48,
                width: 'auto'
            },
            title: "Smartoys",
        },
        gamecash: {
            img: {
                logo: gamecashLogo,
                height: 30,
                width: 'auto'
            },
            title: "Gamecash",
        }

    }
    const selectedStore = computed(() => {
        if (Object.hasOwnProperty.call(state, storeInCookie.value)) {
            return state[storeInCookie.value];
        }
        return null;
    });


    return {
        selectedStore
    }
})